<template>
  <div class="container-fluid">
    <b-row class="steps-container">
      <div
        class="u-pearl"
        :class="step >= 0 ? 'done' : ''"
      >
        <template v-if="step >= 1">
          <div @click="$emit('on:step-0:selected')" class="u-pearl-icon">
            <span class="pointer pearl-icon-check fa fa-check"></span>
          </div>
        </template>
        <template v-else>
          <div class="u-pearl-icon">
            1
          </div>
        </template>
        <label
          class="font-text-title pointer"
        >
          {{ $t('views.client.import.steps.step-0.title') }}
        </label>
      </div>
      <div
        class="u-pearl"
        :class="step >= 1 ? 'done' : ''"
      >
        <template v-if="step >= 2">
          <div @click="$emit('on:step-1:selected')" class="u-pearl-icon">
            <span class="pointer pearl-icon-check fa fa-check"></span>
          </div>
        </template>
        <template v-else>
          <div class="u-pearl-icon">
            2
          </div>
        </template>
        <label
          class="font-text-title pointer"
        >
          {{ $t('views.client.import.steps.step-1.title') }}
        </label>
      </div>
      <div
        :class="step >= 2 ? 'done' : ''"
        class="u-pearl"
      >
        <template v-if="step >= 3">
          <div @click="$emit('on:step-2:selected')" class="u-pearl-icon">
            <span class="pointer pearl-icon-check fa fa-check"></span>
          </div>
        </template>
        <template v-else>
          <div class="u-pearl-icon">
            3
          </div>
        </template>
        <label
          class="font-text-title pointer"
        >
          {{ $t('views.client.import.steps.step-2.title') }}
        </label>
      </div>
      <div
        :class="step >= 3 ? 'done' : ''"
        class="u-pearl"
      >
        <div class="u-pearl-icon">
          4
        </div>
        <label
          class="font-text-title pointer"
        >
          {{ $t('views.client.import.steps.step-3.title') }}
        </label>
      </div>
    </b-row>
    <b-row v-if="step > 0 && step !== 2">
      <b-col cols="12">
        <div class="d-flex justify-content-between align-items-center">
          <h2>{{ $t(title) }}</h2>
          <div class="d-flex steps-control">
            <button @click="$emit('on:prev-step')">
              {{ $t('views.client.import.steps.prev') }}
            </button>
            <button v-if="step < 3" @click="$emit('on:next-step')">
              {{ $t('views.client.import.steps.next') }}
            </button>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: {
    step: {
      type: Number,
      default: 0
    },
  },
  computed: {
    title() {
      return `views.client.import.steps.step-${this.step}.general.title`;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@lazy/client/import/_client-menu.scss";

/deep/ .font-text-title {
  font-size: 13px;
  font-weight: 500;
}
</style>